import client from "./http";

class LandingPagesService {
  constructor(axios) {
    this.axios = axios;
  }

  getList(programId, search) {
    return this.axios.get(`/programs/${programId}/landing-pages`, {
      params: { search },
    });
  }

  getLandingPage(programId, landingPageId) {
    return this.axios.get(`/programs/${programId}/landing-pages/${landingPageId}`);
  }

  add(programId, data) {
    return this.axios.post(`/programs/${programId}/landing-pages`, data);
  }

  update(programId, landingPageId, data) {
    return this.axios.post(`/programs/${programId}/landing-pages/${landingPageId}`, data);
  }

  delete(programId, landingPageId) {
    return this.axios.delete(`/programs/${programId}/landing-pages/${landingPageId}`);
  }
}

const landingPagesService = new LandingPagesService(client);
export default landingPagesService;
