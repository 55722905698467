<template>
  <b-card>
    <!-- Table Top -->
    <b-row>
      <b-col>
        <div class="d-flex align-items-center justify-content-end">
          <div>
            <!-- Create landing page -->
            <action-button
              v-if="unavailableLocales"
              v-b-toggle.add-landing-page-right
              variant="primary"
              style="margin-left: 10px"
            >
              <feather-icon
                icon="PlusIcon"
                size="16"
                class="mr-50"
              />
              <span class="text-nowrap">Create Landing Page</span>
            </action-button>
            <b-sidebar
              id="add-landing-page-right"
              title="Add Landing Page"
              bg-variant="white"
              right
              backdrop
              shadow
              width="50vw"
              @shown="handleShowAOMTextEditor"
              @hidden="handleHideAOMTextEditor"
            >
              <div>
                <add-landing-page
                  :show-a-o-m-text-editor="showAOMTextEditor"
                  :is-program-landing-page="true"
                  :unavailable-locales="unavailableLocales"
                  @landingPageAdded="onCreateLandingPage"
                />
              </div>
            </b-sidebar>
          </div>
        </div>
      </b-col>
    </b-row>

    <!-- table -->
    <landing-pages-list
      ref="landingPagesList"
      @edit="editLandingPage"
      @loaded="handleLoadedLandingPages"
    />

    <b-sidebar
      id="edit-landing-page-right"
      title="Edit Landing Page"
      bg-variant="white"
      right
      backdrop
      shadow
      width="50vw"
      @shown="handleShowAOMTextEditor"
      @hidden="handleEditSideBarHidden"
    >
      <div>
        <edit-landing-page
          :show-a-o-m-text-editor="showAOMTextEditor"
          :is-program-landing-page="true"
          :selected-landing-page="selectedLandingPage"
          @landingPageUpdated="onRefreshItems"
        />
      </div>
    </b-sidebar>
  </b-card>
</template>

<script>
import { BButton, BRow, BCol, BCard, BSidebar, VBToggle } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import AddLandingPage from "./AddLandingPage.vue";
import EditLandingPage from "./EditLandingPage.vue";
import LandingPagesList from "./LandingPagesList.vue";
import ActionButton from "@/views/components/common/ActionButton.vue";


export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    AddLandingPage,
    EditLandingPage,
    LandingPagesList,
    BSidebar,
    ActionButton
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      unavailableLocales: [],
      selectedLandingPage: null,
      showAOMTextEditor: false
    };
  },
  methods: {
    onRefreshItems() {
      this.$refs.landingPagesList.loadItems();
    },
    editLandingPage(landingPage) {
      this.selectedLandingPage = landingPage;
    },
    async onCreateLandingPage() {
      this.onRefreshItems();
    },
    handleShowAOMTextEditor () {
      this.showAOMTextEditor = true;
    },
    handleHideAOMTextEditor () {
      this.showAOMTextEditor = false;
    },
    handleEditSideBarHidden() {
      this.handleHideAOMTextEditor();
      this.selectedLandingPage = null;
    },
    handleLoadedLandingPages (locales) {
      this.unavailableLocales = locales;
    }
  },
};
</script>
<style lang="scss">
  $sidebar-header-text-color: #808080;
    .b-sidebar > .b-sidebar-header{
      flex-direction: row-reverse;
      background: #f3f3f3;
      color: $sidebar-header-text-color;
  
      #add-landing-page-right___title__ {
        font-size: .8em;
        flex: 2;
      }
      #edit-landing-page-right___title__ {
        font-size: .8em;
        flex: 2;
      }
    }
</style>