<template>
  <!-- table -->
  <vue-good-table
    class="mt-3"
    mode="remote"
    :is-loading="isLoading"
    :columns="columns"
    :rows="rows"
    :rtl="directionIsRTL"
    :search-options="{
      enabled: true,
      externalQuery: searchTerm,
    }"
    :pagination-options="{
      enabled: true,
      perPage: perPage,
    }"
    style-class="vgt-table striped"
    @on-page-change="onPageChange"
    @on-sort-change="onSortChange"
    @on-column-filter="onColumnFilter"
    @on-per-page-change="onPerPageChange"
  >
    <template
      slot="table-row"
      slot-scope="props"
    >
      <!-- Column: Actions -->
      <div
        v-if="props.column.field === 'actions'"
        cols="12"
        md="2"
        class="d-flex vgt-center-align"
      >
        <b-button
          variant="outline-none"
          @click="previewLandingPage(props.row)"
        >
          <feather-icon
            icon="ExternalLinkIcon"
            size="20"
          />
        </b-button>

        <action-button
          variant="outline-none"
          @click="deleteLandingPage(props.row.id)"
        >
          <feather-icon
            icon="TrashIcon"
            size="20"
          />
        </action-button>
      </div>
      <div
        v-else-if="props.column.field === 'header'"
        cols="12"
        md="2"
        class="justify-content-between flex-wrap"
      >
        <b-link
          @click="editLandingPage(props.row)"
        >
          {{ props.row.header || 'No title' }}
        </b-link>
      </div>
      <!-- Column: Common -->
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>
    <!-- pagination -->
    <template
      slot="pagination-bottom"
      slot-scope="props"
    >
      <table-pagination
        :per-page="perPage"
        :total="total"
        @perPageChanged="
          (value) => props.perPageChanged({ currentPerPage: value })
        "
        @pageChanged="(value) => props.pageChanged({ currentPage: value })"
      />
    </template>
  </vue-good-table>
</template>

<script>
import { mapGetters } from "vuex";
import ActionButton from "@/views/components/common/ActionButton.vue";

import {
  BButton,
  VBToggle,
  BLink
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import TablePagination from "@/views/components/pagination/TablePagination.vue";
import landingPagesService from "@/services/landingPagesService";
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import useAomTableConfig from "@aom-core/useAomTableConfig.js";

export default {
  components: {
    VueGoodTable,
    BButton,
    TablePagination,
    BLink,
    ActionButton
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      isLoading: false,
      element: {},
      dir: false,

      columns: [
        {
          label: "Header",
          field: "header",
          filterOptions: {
            enabled: true,
            placeholder: "Search Header",
          },
        },

        {
          label: "Locale",
          field: "localeName",
          filterOptions: {
            enabled: false,
          },
        },
        {
          field: "actions",
          sortable: false,
          width: "10em",

        },
      ],
      columnFilters: [],
      sort: [],
      rows: [],
      searchTerm: "",
    };
  },
  computed: {
    ...mapGetters('programs', ['defaultProgramPortal']),

    directionIsRTL() {
      return store.state.appConfig.isRTL;
    },
    currentProgram() {
      return this.$store.getters['programs/defaultProgram'];
    }
  },
  created() {
    this.loadItems();
  },
  methods: {
    dateFilter(date, filterString) {
      return (date = Date.parse(date) >= Date.parse(filterString));
    },

    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },

    onPerPageChange(params) {
      this.perPage = params.currentPerPage;
      this.loadItems();
    },

    onSortChange(params) {
      let field = params[0].field;
      let type = params[0].type;
      if (type !== 'none') {
        this.sort = [{ field: field, type: type }];
      } else {
        this.sort = [];
      }
      this.loadItems();
    },

    onColumnFilter(params) {
      const columnFilters = [];
      for (let col of ["header"]) {
        if (params.columnFilters[col]) {
          columnFilters.push({
            field: col,
            value: params.columnFilters[col],
          });
        }
      }
      this.columnFilters = columnFilters;
      this.loadItems();
    },

    async loadItems() {
      try {
        this.isLoading = true;
        const response = await landingPagesService.getList(
          this.$route.params.id,
          {
            page: this.page,
            perPage: this.perPage,
            columnFilters: this.columnFilters,
            sort: this.sort,
          }
        );
        this.total = response.data.total;
        this.rows = response.data.items.map(item => ({
          ...item,
          localeName: item.locale.name
        }));
        this.$emit('loaded', response.data.items.map(item => item.locale_id));
      } catch (e) {
        this.$toast(makeErrorToast("Landing page list not loaded."));
        this.$log.error(e);
      } finally {
        this.isLoading = false;
      }
    },

    editLandingPage(landingPage) {
      this.$nextTick(()=> {
        this.$emit('edit', landingPage);
      });
      this.$root.$emit("bv::toggle::collapse", "edit-landing-page-right");
    },

    async deleteLandingPage(id) {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to delete this landing page? This action cannot be undone",
          {
            title: "Delete Landing Page",
            size: "sm",
            okVariant: "primary",
            okTitle: "Delete",
            cancelTitle: "Cancel",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async value => {
          if (value) {
            try {
              const response = await landingPagesService.delete(
                this.$route.params.id,
                id
              );

              if (response) {
                this.$toast(makeSuccessToast("Landing page deleted successfully."));
                this.loadItems();
              }
            } catch (e) {
              const { data } = e.response;
              this.$log.error(e);
              this.$toast(makeErrorToast(data.message));
            }
          }
        });
    },

    previewLandingPage(landingPage) {
      window.open(`${this.defaultProgramPortal}?lang=${landingPage.locale_id}&portal=champion`, '_blank');
    }
  },
  setup() {
    const { total, perPage, page } = useAomTableConfig();

    return {
      total, 
      perPage, 
      page
    };
  }
};
</script>

<style lang="scss">
@import "/src/assets/scss/vue-good-table.scss";
</style>
